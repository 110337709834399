import { Component, Vue, Inject, Watch } from 'vue-property-decorator';

import { required } from 'vuelidate/lib/validators';

import AlertService from '@/shared/alert/alert.service';

import AppUserService from '@/entities/app-user/app-user.service';
import { IAppUser } from '@/shared/model/app-user.model';

import { ICustomer, Customer } from '@/shared/model/customer.model';
import CustomerService from '@/entities/customer/customer.service';

const validations: any = {
  customer: {
    name: {},
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    dob: {},
    job: {},
    street: {},
    zip: {},
    city: {},
  },
};

@Component({
  validations,
})
export default class CustomerUpdate extends Vue {
  public customer: ICustomer = new Customer();
  public customers: ICustomer[] = [];
  public appUsers: IAppUser[] = [];
  public coInsured: ICustomer[] = [];
  public isSaving = false;
  public currentLanguage = '';
  @Inject('customerService') private customerService: () => CustomerService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('appUserService') private appUserService: () => AppUserService;

  public get hasCoInsured(): boolean {
    return this.coInsured && this.coInsured.length > 0;
  }

  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (to.params.customerId) {
        vm.retrieveCustomer(to.params.customerId);
        vm.loadCoInsured(to.params.customerId);
      }
      vm.initRelationships();
    });
  }

  @Watch('$route.params.customerId')
  async routeChanged(newCustomerId, _oldCustomerId) {
    await this.initRelationships();
    if (newCustomerId) {
      await this.retrieveCustomer(newCustomerId);
      await this.loadCoInsured(newCustomerId);
    }
  }

  public async save(): Promise<void> {
    this.isSaving = true;
    try {
      if (this.customer.id) {
        await this.customerService().update(this.customer);
      } else {
        await this.customerService().create(this.customer);
      }
      this.isSaving = false;
      const msg = 'Kunde erfolgreich gespeichert';
      this.alertService().showSuccess(this, msg);
      await this.previousState();
    } catch (e) {
      const msg = 'Fehler beim Speichern des Kunden';
      this.alertService().showCustomServerError(this, msg, e);
    } finally {
      this.isSaving = false;
    }
  }

  /**
   * loads customers from the server
   * @param customerId
   */
  public async retrieveCustomer(customerId): Promise<void> {
    try {
      this.customer = await this.customerService().find(customerId);
    } catch (e) {
      const msg = 'Fehler beim Laden des Kunden';
      this.alertService().showCustomServerError(this, msg, e);
    }
  }

  public async previousState(): Promise<void> {
    await this.$router.push({ name: 'Customer' });
  }

  /**
   * loads all customers from the server
   */
  public async initRelationships(): Promise<void> {
    try {
      const paginationQuery = {
        page: 0,
        size: 1000,
        sort: ['lastName,asc'],
      };
      const res = await this.customerService().retrieve(paginationQuery);
      const allCustomers = res.data;
      //remove all that have mainInsured set
      const customersWithoutInsured = allCustomers.filter(c => !c.mainInsured);
      //remove self if id is set
      const customerRouteId = this.$route.params.customerId;
      if (customerRouteId) {
        this.customers = customersWithoutInsured.filter(c => c.id !== +customerRouteId);
      } else {
        this.customers = customersWithoutInsured;
      }
    } catch (e) {
      const msg = 'Fehler beim Laden des Kunden';
      this.alertService().showCustomServerError(this, msg, e);
    }
  }

  public async loadCoInsured(customerId: number): Promise<void> {
    try {
      this.coInsured = await this.customerService().retrieveCoInsured(customerId);
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden der Mitversicherten');
    }
  }
}
