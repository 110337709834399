var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _c("div", { staticClass: "" }, [
      _c("div", { staticClass: "" }, [
        _c(
          "form",
          {
            attrs: { name: "editForm", novalidate: "", role: "form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save()
              },
            },
          },
          [
            _c(
              "h2",
              {
                attrs: {
                  id: "cdpRisikoApp.customer.home.createOrEditLabel",
                  "data-cy": "CustomerCreateUpdateHeading",
                },
              },
              [
                _vm._v(
                  "\n          Kunde " +
                    _vm._s(_vm.customer.id ? "anlegen" : "bearbeiten") +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-lastName" },
                  },
                  [_vm._v("Nachname")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.lastName.$model,
                      expression: "$v.customer.lastName.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.lastName.$invalid,
                    invalid: _vm.$v.customer.lastName.$invalid,
                  },
                  attrs: {
                    id: "customer-lastName",
                    "data-cy": "lastName",
                    name: "lastName",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.lastName.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.lastName,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.$v.customer.lastName.$anyDirty &&
                _vm.$v.customer.lastName.$invalid
                  ? _c("div", [
                      !_vm.$v.customer.lastName.required
                        ? _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [
                              _vm._v(
                                "\n                Pflichtfeld.\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-firstName" },
                  },
                  [_vm._v("Vorname")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.firstName.$model,
                      expression: "$v.customer.firstName.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.firstName.$invalid,
                    invalid: _vm.$v.customer.firstName.$invalid,
                  },
                  attrs: {
                    id: "customer-firstName",
                    "data-cy": "firstName",
                    name: "firstName",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.firstName.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.firstName,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.$v.customer.firstName.$anyDirty &&
                _vm.$v.customer.firstName.$invalid
                  ? _c("div", [
                      !_vm.$v.customer.firstName.required
                        ? _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [
                              _vm._v(
                                "\n                Pflichtfeld.\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-dateOfBirth" },
                  },
                  [_vm._v("Geburtsdatum")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.dob.$model,
                      expression: "$v.customer.dob.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.dob.$invalid,
                    invalid: _vm.$v.customer.dob.$invalid,
                  },
                  attrs: {
                    id: "customer-dateOfBirth",
                    "data-cy": "dateOfBirth",
                    name: "dateOfBirth",
                    type: "date",
                  },
                  domProps: { value: _vm.$v.customer.dob.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.dob,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-street" },
                  },
                  [_vm._v("Strasse")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.street.$model,
                      expression: "$v.customer.street.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.street.$invalid,
                    invalid: _vm.$v.customer.street.$invalid,
                  },
                  attrs: {
                    id: "customer-street",
                    "data-cy": "street",
                    name: "street",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.street.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.street,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-zip" },
                  },
                  [_vm._v("PLZ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.zip.$model,
                      expression: "$v.customer.zip.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.zip.$invalid,
                    invalid: _vm.$v.customer.zip.$invalid,
                  },
                  attrs: {
                    id: "customer-zip",
                    "data-cy": "zip",
                    name: "zip",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.zip.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.zip,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-city" },
                  },
                  [_vm._v("Ort")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.city.$model,
                      expression: "$v.customer.city.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.city.$invalid,
                    invalid: _vm.$v.customer.city.$invalid,
                  },
                  attrs: {
                    id: "customer-city",
                    "data-cy": "city",
                    name: "city",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.city.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.city,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-job" },
                  },
                  [_vm._v("Beruf")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.customer.job.$model,
                      expression: "$v.customer.job.$model",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    valid: !_vm.$v.customer.job.$invalid,
                    invalid: _vm.$v.customer.job.$invalid,
                  },
                  attrs: {
                    id: "customer-job",
                    "data-cy": "job",
                    name: "job",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.customer.job.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.customer.job,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-control-label",
                    attrs: { for: "customer-mainInsured" },
                  },
                  [_vm._v("Mitversichert mit:")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customer.mainInsured,
                        expression: "customer.mainInsured",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "customer-mainInsured",
                      "data-cy": "mainInsured",
                      name: "mainInsured",
                      disabled: _vm.hasCoInsured,
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.customer,
                          "mainInsured",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }),
                    _vm._v(" "),
                    _vm._l(_vm.customers, function (customerOption) {
                      return _c(
                        "option",
                        {
                          key: customerOption.id,
                          domProps: {
                            value:
                              _vm.customer.mainInsured &&
                              customerOption.id === _vm.customer.mainInsured.id
                                ? _vm.customer.mainInsured
                                : customerOption,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                customerOption.lastName +
                                  " " +
                                  customerOption.firstName
                              ) +
                              "\n              "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.hasCoInsured
                  ? _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        "\n              Bitte beachten Sie, dass Hauptversicherte nicht mitversichert sein können.\n            "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.customer.id
              ? _c("div", [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      "\n            Erstellt am: " +
                        _vm._s(
                          _vm._f("formatGermanDateTime")(
                            _vm.customer.createdTimestamp
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: {
                    id: "cancel-save",
                    "data-cy": "entityCreateCancelButton",
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.previousState()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Abbrechen")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    id: "save-entity",
                    disabled: _vm.isSaving || _vm.$v.$invalid,
                    "data-cy": "entityCreateSaveButton",
                    type: "submit",
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "save" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Speichern")]),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.hasCoInsured
          ? _c("section", [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v("Mitversicherte:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "co-insured-wrapper d-flex flex-wrap",
                  staticStyle: { gap: "10px" },
                },
                _vm._l(_vm.coInsured, function (subCustomer) {
                  return _c(
                    "div",
                    {
                      key: subCustomer.id,
                      staticClass: "card card-body border-0 shadow-sm",
                      staticStyle: {
                        "min-width": "300px",
                        "max-width": "fit-content",
                      },
                    },
                    [
                      _c("p", { staticClass: "font-weight-bold mb-3" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              subCustomer.firstName + " " + subCustomer.lastName
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "text-right font-weight-normal",
                          attrs: {
                            to: {
                              name: "CustomerEdit",
                              params: { customerId: subCustomer.id },
                            },
                          },
                        },
                        [
                          _vm._v("\n              mehr infos\n              "),
                          _c("font-awesome-icon", {
                            attrs: { icon: "chevron-right" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }